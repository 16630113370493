/**
 * Marker SCSS.
 */
.marker {
  svg {
    color: $navy-blue;
    font-size: 3rem;
  }

  &__text {
    width: 200px;
    margin-top: 5px;
    padding: 12px 15px;
    background: rgba($medium-blue, 0.88);
    border-radius: 4px;
    color: $white;
    font-size: 0.85rem;
    line-height: 1.2rem;
  }
}

/**
 * Variables SCSS.
 */

/**
 * Blues.
 */
$navy-blue: #06274b;
$medium-blue: #2980b9;
$light-blue: #54aff0;
$lightest-blue: #d5e6f6;

/**
 * Greys and Whites.
 */
$dark-grey: #333;
$medium-grey: #888;
$light-grey: #d6d6d6;
$lightest-grey: #fafafa;
$white: #fff;

/**
 * Screen widths.
 */
$tablet-width: 768px;
$desktop-width: 991px;

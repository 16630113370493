/**
 * Other Information SCSS.
 */
#other-information {
  .other-information {
    &__heading {
      margin-bottom: 18px;
      font-size: 1.2rem;
      font-weight: bold;
    }

    &__subheading {
      font-size: 1rem;
    }

    &__subtitle {
      margin: 10px 0 0;
      font-size: 0.9rem;
      font-weight: 700;
    }
  }

  > .row {
    margin-bottom: 35px;
  }
}

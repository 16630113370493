/**
  Home SCSS.
*/
html,
body {
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: $dark-grey;

  @include tablet {
    padding: 0;
  }

  @include desktop {
    padding: 0;
  }
}

a {
  color: $medium-blue;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $light-blue;
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $navy-blue;
}

h2 {
  font-size: 1rem;

  @include tablet {
    font-size: 1.3rem;
  }

  &.section-heading {
    margin: 35px 0;
    color: rgba($dark-grey, 0.55);
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
}

h3 {
  font-weight: 400;
}

svg {
  padding-bottom: 5px;
}

/**
	Misc.
*/
.divider-before::before,
.divider-after::after {
  display: block;
  width: 80px;
  height: 1px;
  background: $light-grey;
}

.divider-before {
  &::before {
    content: '';
    margin: 0 auto 15px;
  }
}

.divider-after {
  &::after {
    content: '';
    margin: 15px auto 0;
  }
}

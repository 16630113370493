/**
 * Major Projects SCSS.
 */
#major-projects {
  padding: 30px 0 0;

  .container-fluid {
    margin-top: 30px;
    margin-bottom: 50px;

    &:first-child {
      margin-top: 0;
      background: $lightest-grey;
    }

    &:last-child {
      padding-top: 30px;
      padding-bottom: 55px;
    }
  }

  .project-item {
    &__image {
      img {
        @include mobile {
          width: 100%;
          height: auto;
        }

        @include tablet {
          height: 400px;
        }

        @include desktop {
          height: 400px;
        }
      }
    }

    &__logo {
      height: 50px;
      margin-bottom: 20px;

      &--app-icon {
        height: 100px;
        border-radius: 15px;
      }
    }

    &__text {
      padding-top: 30px;
      padding-left: 0;
      padding-right: 0;

      &__link {
        svg {
          font-size: 1.3rem;
        }
      }
    }

    &--last-cigarette-app {
      padding-bottom: 60px;

      .project-item__image {
        img {
          @include tablet {
            height: 190px;
            margin-top: 50px;
          }

          @include desktop {
            height: 350px;
            margin-top: 50px;
            margin-left: -130px;
          }
        }
      }
    }

    &--collectstor {
      .project-item__image {
        img {
          @include tablet {
            height: 310px;
            margin-left: -250px;
          }

          @include desktop {
            margin-left: -250px;
          }
        }
      }

      .project-item__logo {
        @include mobile {
          width: 100%;
          height: auto;
        }
      }
    }

    &--collectstor-app {
      .project-item__image {
        img {
          @include tablet {
            height: 350px;
          }
        }
      }
    }

    .divider-after::after {
      content: '';
      margin: 30px auto 0;
    }
  }
}

/**
 * My Location SCSS.
 */
#my-location {
  background-color: $light-grey;

  #map {
    width: 100%;
    height: 400px;
  }
}

/**
	Resume SCSS.
*/
.resume {
  margin-bottom: 30px;

  &__download {
    svg {
      margin-right: 8px;
      font-size: 1.6rem;
    }
  }
}

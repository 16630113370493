.progress-graph {
  margin-bottom: 10px;
  padding: 0;

  &__label {
    font-size: 0.8rem;
    font-weight: 700;
    text-align: left;

    @include desktop {
      padding: 0;
      text-align: right;
    }
  }

  &__progress {
    padding-top: 5px;

    .progress {
      height: 10px;
    }

    .progress-bar {
      background: $medium-blue;
      transition: 1s ease;
      transition-delay: 0.5s;
    }
  }
}

/**
	Hero SCSS.
*/
#top {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 10px;
  text-align: center;

  h1 {
    margin-bottom: 25px;
    font-size: 2rem;
    font-weight: 100;
    text-transform: uppercase;

    @include tablet {
      font-size: 3rem;
    }

    @include desktop {
      margin: 0 0 8px;
      font-size: 3.5rem;
    }
  }

  h2 {
    display: block;
    margin-top: 10px;
    font-size: 1.4rem;
    font-weight: 300;

    &::after {
      content: '';
      margin-right: 0;
    }

    @include tablet {
      display: inline;
      font-size: 1.6rem;
      font-weight: 300;

      &::after {
        content: '';
        margin-right: 40px;
      }
    }

    @include desktop {
      display: inline;
      &::after {
        content: '';
        margin-right: 55px;
      }
      &:last-child::after {
        content: '';
        margin-right: 0;
      }
    }
  }

  .specialty {
    margin: 8px 0;
  }

  .links {
    margin-top: 18px;
    font-size: 1.25rem;

    a {
      display: block;
      margin-right: 0;
      font-size: 1rem;

      &:last-child {
        margin-right: 0;
      }

      @include tablet {
        display: inline;
        margin-right: 40px;
      }

      @include desktop {
        display: inline;
        margin-right: 40px;
      }
    }
  }

  .scroll-for-more {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25px;
    margin: 0 auto;
    font-size: 0.6rem;

    @include tablet {
      font-size: 0.8rem;
    }

    @include desktop {
      font-size: 1rem;
    }
  }
}

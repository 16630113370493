/**
	Card SCSS.
*/
.card {
  margin-bottom: 40px;
  border: 0;

  @include tablet {
    margin-bottom: 0;
  }

  @include desktop {
    margin-bottom: 0;
  }

  &__image {
    width: 60px;
    border-radius: 10px;
  }

  &__title {
    margin-top: 25px;
    font-size: 1.15rem;
    font-weight: 400;
  }

  &__description {
    font-size: 0.9rem;
  }

  .coding-language {
    margin-bottom: 25px;

    &__text {
      margin-right: 12px;
      padding: 3px 8px;
      background: $lightest-blue;
      border-radius: 4px;
      color: $dark-grey;
      font-size: 0.7rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__links {
    svg {
      margin-top: 2px;
      margin-right: 5px;
    }
  }
}

/**
	Thanks SCSS.
*/
#thanks {
  padding: 40px 0;
  text-align: center;

  @include desktop {
    padding: 120px 0;
  }

  h3 {
    display: block;
    color: $medium-blue;
    font-size: 1rem;

    &::after {
      content: '';
      margin-right: 0;
    }

    @include desktop {
      display: inline;
      padding: 40px 0;
      font-size: 1.65rem;

      &::after {
        content: '';
        margin-right: 30px;
      }

      &:last-child::after {
        content: '';
        margin-right: 0;
      }
    }
  }
}

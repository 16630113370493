/**
	Social SCSS.
*/
#social {
  text-align: center;

  .social__text {
    font-size: 0.8rem;
    font-weight: 700;
  }

  a {
    margin-right: 15px;
    color: $navy-blue;
    font-size: 1.5rem;

    @include tablet {
      margin-right: 35px;
      font-size: 2rem;
    }

    @include desktop {
      margin-right: 35px;
      font-size: 2rem;
    }

    &:hover,
    &:focus {
      color: $medium-blue;
    }

    &:last-child {
      margin: 0;
    }
  }
}

/**
 * Nav SCSS.
 *
 * @link https://codyhouse.co/gem/vertical-fixed-navigation/
 */
#nav {
  svg {
    font-size: 0.6rem;
  }

  .nav-trigger {
    display: none;
  }

  .touch & {
    position: fixed;
    right: 5%;
    bottom: 10px;
    width: 90%;
    max-width: 400px;
    max-height: 90%;
    z-index: 1;

    .nav-list {
      display: none;
      background: rgba($light-grey, 0.95);
      border-radius: 0.25em;

      a {
        display: block;
        padding: 1em;
      }

      &--open {
        display: block;
      }

      &__item {
        border-bottom: 1px solid rgba($medium-grey, 0.3);

        &:last-child {
          border-bottom: 0;
        }

        &__dot {
          display: none;
        }
      }
    }

    .nav-trigger {
      display: block;
      position: fixed;
      bottom: 30px;
      right: 5%;
      height: 58px;
      width: 58px;
      border-radius: 0.25em;
      background: rgba($light-grey, 0.45);
      color: $medium-blue;
      z-index: 2;

      &:hover {
        color: $light-blue;
      }

      svg {
        font-size: 1.6rem;
      }

      &__container {
        position: absolute;
        left: 50%;
        top: 50%;
        padding-top: 5px;
        transform: translateX(-50%) translateY(-50%);
      }

      &--open {
        background-color: transparent;
      }
    }
  }

  .no-touch & {
    position: fixed;
    top: 50%;
    bottom: auto;
    right: 30px;
    transform: translateY(-50%);
    z-index: 1;

    .nav-list {
      a {
        display: inline-block;
        @include clearfix;

        &:hover {
          .nav-list__item__dot {
            transform: scale(1.3);
          }

          .nav-list__item__label {
            opacity: 1;
            transform: scale(1);
          }
        }

        &:focus {
          .nav-list__item__dot {
            color: $medium-blue;
          }
        }
      }

      &__item {
        margin: 5px 0;
        text-align: right;

        &__dot,
        &__label {
          display: inline-block;
        }

        &__dot {
          position: relative;
          top: 2px;
          height: 12px;
          width: 12px;
          transition:
            transform 0.4s,
            background-color 0.5s;
          transform-origin: 50% 50%;
        }

        &__label {
          position: relative;
          margin-right: 9px;
          font-size: 1rem;
          opacity: 0;
          transition:
            transform 0.3s,
            opacity 0.2s;
          transform-origin: 100% 50%;
        }
      }
    }
  }
}

/**
	Contact Info SCSS.
*/
#contact-info {
  padding: 30px 0 90px;

  .contact-info__email {
    font-size: 1.8rem;
    font-weight: 100;
    text-align: center;

    @include tablet {
      margin-bottom: 40px;
      font-size: 3.2rem;
    }

    @include desktop {
      margin-bottom: 40px;
      font-size: 3.5rem;
    }
  }
}

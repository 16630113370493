/**
 * Resume Entry SCSS.
 */
.resume-entry {
  margin-bottom: 15px;
  padding-bottom: 15px;

  &__company,
  &__role {
    margin: 0;
    margin-bottom: 5px;
  }

  &__company {
    font-size: 1.2rem;
  }

  &__role {
    font-size: 0.9rem;
    font-weight: 700;
  }

  &__date {
    color: $medium-grey;
    font-size: 0.9rem;
    font-weight: 700;
    text-align: left;

    @include tablet {
      text-align: right;
    }

    @include desktop {
      text-align: right;
    }
  }

  &__location {
    color: $medium-grey;
    font-size: 0.9rem;
    font-weight: normal;
    text-align: left;

    @include tablet {
      text-align: right;
    }

    @include desktop {
      text-align: right;
    }
  }

  &__description {
    font-size: 0.9rem;
    transition: 5s;

    &:before {
      content: '';
      display: block;
      width: 50px;
      height: 1px;
      margin: 15px 0 18px;
      background: $light-grey;
    }
  }

  &__toggle {
    padding-top: 8px;
    border-bottom: 1px solid $light-grey;
    color: $medium-blue;
    cursor: pointer;
    font-size: 0.85rem;
    text-align: right;

    &:hover {
      color: $light-blue;
    }

    &--disabled {
      padding-top: 8px;
      border-bottom: 1px solid $light-grey;
    }

    svg {
      font-size: 1.6rem;
    }
  }
}

/**
	Not Found (404) SCSS.
*/
#not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  div {
    margin-top: -50px;
  }

  h1 {
    font-size: 4rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  h3 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.3rem;
  }
}

/**
  SCSS includes.
*/
@charset "utf-8";

// CSS reset.
@import-normalize;
@import-sanitize;

// Fonts.
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;700&display=swap');

// Bootstrap.
@import '~bootstrap/scss/bootstrap';

@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/global';

// Errors.
@import 'styles/errors/not-found';

// Layouts.
@import 'styles/layout/footer';
@import 'styles/layout/nav';

// CV.
@import 'styles/cv/';
@import 'styles/cv/resume';
@import 'styles/cv/resume-entry';

// Hero.
@import 'styles/hero/';

// Projects.
@import 'styles/projects/';
@import 'styles/projects/card';
@import 'styles/projects/major-projects';

// Skills.
@import 'styles/skills/';
@import 'styles/skills/progress-bar';
@import 'styles/skills/skillset';

// My Location.
@import 'styles/my-location/';
@import 'styles/my-location/marker';

// Contact.
@import 'styles/contact/contact-info';
@import 'styles/contact/social';
@import 'styles/contact/thanks';
